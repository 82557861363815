import tableState from "../../../templates/table/state";

export default () => ({
  ...tableState(),
  invoiceItemId: null,
  creditedInvoiceId: null,
  lead: {},
  submission: {},
  address: {}
});
